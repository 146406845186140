import React from "react";
import VIcon from "../../assets/images/IconHeadV.svg";

import Tiktok from "../../assets/images/Tiktok.svg";

import Telegram from "../../assets/images/Telegram.svg";

import Twitter from "../../assets/images/Twitter.svg";

import Facebook from "../../assets/images/Facebook.svg";

import Instagram from "../../assets/images/Instagram.svg";

function FooterSection() {
  return (
    <div className="footer-bg w-full flex justify-center">
      <div className="main-content ">
        <div className="footer-content flex justify-between text-cyan-50 ">
          <div className="flex-1">
            <img src={VIcon} alt="V" />
            <h4>
              Pioneering Arbitrage <br />
              Excellence in Global Crypto <br />
              Trading
            </h4>
            <ul className="footer-social flex items-center">
              <li>
                <h5>Follow us on:</h5>
              </li>
              <li>
                <img src={Tiktok} alt="Tiktok" />
              </li>
              <li>
                <img src={Telegram} alt="Telegram" />
              </li>
              <li>
                <img src={Twitter} alt="Twitter" />
              </li>
              <li>
                <img src={Facebook} alt="Facebook" />
              </li>
              <li>
                <img src={Instagram} alt="Instagram" />
              </li>
            </ul>
          </div>
          <div className="flex-1 footer-menu flex items-center justify-end">
            <ul>
              <li>
                <a href="/">For You</a>
              </li>
              <li>
                <a href="/">Learn</a>
              </li>
              <li>
                <a href="/">Announcements</a>
              </li>
              <li>
                <a href="/">About</a>
              </li>
            </ul>

            <ul>
              <li>
                <a href="/">Support</a>
              </li>
              <li>
                <a href="/">Contact Us</a>
              </li>
              <li>
                <a href="/">Terms of Services</a>
              </li>
              <li>
                <a href="/">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom text-[#77718a]">
          <p>CopyrightCopyright © 2023. ViCA. All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default FooterSection;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "../projects/pages/Index";

function RouteHandler() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RouteHandler;

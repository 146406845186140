import React from "react";
import DownArrow from "../../assets/images/DownArrow.svg";
import VIcon from "../../assets/images/IconHeadV.svg";
import Logo from "../../assets/images/Logo.svg";

function HeaderSection() {
  return (
    <div className="h-84 header-bg w-full flex justify-center">
      <div className="main-content flex justify-between text-cyan-50">
        <div className="flex justify-center logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="flex-1 menu flex items-center	">
          <ul>
            <li className="active">
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/">Wallet</a>
              <img src={DownArrow} alt="DownArrow" />
            </li>
            <li>
              <a href="/">Dashboard</a>
            </li>
            <li>
              <a href="/">Invest</a>
              <img src={DownArrow} alt="DownArrow" />
            </li>
            <li>
              <a href="/">Security</a>
            </li>
          </ul>
        </div>
        <div className="flex items-center justify-end flex-1 gap-3 pr-3">
          <div className="flex items-center justify-end gap-2 mr-9">
            <img src={VIcon} alt="V" />{" "}
            <span className="text-green">.052$</span>
          </div>
          <button type="button" className="login-register-btn">
            Login
          </button>
          <button type="button" className="header-register-btn">
            Register
          </button>
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;

import React from "react";
import TimeLineIcon1 from "../../assets/images/IconTimeline1.svg";
import TimeLineIcon2 from "../../assets/images/IconTimeline2.svg";
import TimeLineIcon3 from "../../assets/images/IconTimeline3.svg";
import TimeLineIcon4 from "../../assets/images/IconTimeline4.svg";
import TimeLineIcon5 from "../../assets/images/IconTimeline5.svg";
import Robot2 from "../../assets/images/Robot-2.svg";
import Robot from "../../assets/images/Robot.svg";
import RobotSide from "../../assets/images/RobotSide.svg";
import FooterSection from "../section/FooterSection";
import HeaderSection from "../section/HeaderSection";
import HeroSection from "../section/HeroSection";

function Index() {
  return (
    <div className="main">
      {/* Header Section Start */}
      <HeaderSection />
      {/* Header Section End */}

      {/* Hero Section Start */}
      <HeroSection />
      {/* Hero Section End */}
      {/* Sceond Section Start */}
      <div className="second-section w-full flex justify-center">
        <div className="main-content flex justify-between items-center mt-10">
          <div className="flex-1 flex flex-col gap-10">
            <h2 className="text-5xl text-[#D7D1E3]">
              Unlock Your Passive <br />
              Income Potential
              <br /> with ViBOT!
            </h2>
            <h4 className="text-lg text-[#8F899E]">
              Welcome to ViBOT, Your gateway to hassle-
              <br />
              free crypto trading! Our sophisticated <br />
              trading bot is designed to generate <br />
              consistent profits for you.
            </h4>
            <button type="button" className="explore-btn">
              Explore Dashboard
            </button>
          </div>
          <div className="flex-1 flex robot-bg">
            <img src={Robot} alt="Robot" />
            <img
              src={RobotSide}
              alt="RobotSide"
              style={{ marginLeft: "-51px" }}
            />
          </div>
          <div className="flex-1 matrics-section">
            <ul>
              <li>
                <h2>1,324,847,729 ViCA</h2>
                <h6>ViCA Burned</h6>
              </li>
              <li>
                <h2>30,000,00 ViCA</h2>
                <h6>Staked ViCA</h6>
              </li>
              <li>
                <h2>21,604,872.672 USD</h2>
                <h6>Market Cap</h6>
              </li>
              <li>
                <h2>675,152,272 ViCA</h2>
                <h6>Tradable Circulating Supply</h6>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Sceond Section End */}
      {/* Third Section Start */}
      <div className="third-section w-full flex justify-center">
        <div className="main-content third-section-bg flex flex-col text-center items-center mt-10 gap-12 justify-center">
          <h1 className="text-6xl text-[#E9E2F7]">
            The future of your <br />
            financial growth starts here
          </h1>
          <div>
            <button type="button" className="invest-btn">
              Invest Now
            </button>
            <button type="button" className="talk-btn">
              Let’s Talk
            </button>
          </div>
        </div>
      </div>
      {/* Third Section End */}
      {/* Four Section Start */}
      <div className="forth-section w-full flex justify-center">
        <div className="main-content flex flex-col text-center items-center mt-10 gap-12 justify-center">
          <h1 className="text-5xl	text-[#D7D1E3]">
            How ViBOT <br />
            works to maximise your funds
          </h1>
          <div className="flex forth-section-content">
            <div className="flex-1">
              <ul>
                <li>
                  <img src={TimeLineIcon1} alt="1" />
                  <h4>Arbitrage Excellence</h4>
                </li>
                <li>
                  <img src={TimeLineIcon2} alt="1" />

                  <h4>Global Integration</h4>
                </li>
                <li className="active">
                  <img src={TimeLineIcon3} alt="1" />

                  <h4 className="flex-1">Stable Asset Management</h4>
                  <div>
                    ViBOT prioritizes stability in asset management by
                    maintaining an equal balance of ETH and USDT on both
                    exchanges. This equilibrium not only ensures stability but
                    also facilitates the instantaneous execution of buy and sell
                    orders, a key factor in ViBOT's success.
                  </div>
                </li>
                <li>
                  <img src={TimeLineIcon4} alt="1" />
                  <h4>Adaptive to Market Dynamics</h4>
                </li>
                <li>
                  <img src={TimeLineIcon5} alt="1" />
                  <h4>Efficient Risk Mitigation</h4>
                </li>
              </ul>
            </div>
            <div className="flex-1 flex justify-end items-center">
              <img src={Robot2} alt="robot" />
            </div>
          </div>
        </div>
      </div>
      {/* Four Section End */}
      {/* Footer Section Start */}
      <FooterSection />
      {/* Footer Section End */}
    </div>
  );
}

export default Index;

import React from "react";

function HeroSection() {
  return (
    <div className="hero-bg w-full flex justify-center">
      <div className="main-content flex justify-center items-center mt-10">
        <div className="hero-content flex items-center flex-col text-center">
          <h1 className="text-7xl font-bold	text-white-100">
            Invest with ViBoT!
          </h1>
          <h6 className="text-xl text-white-100">
            Join Over 3000 People who trusted our ViBOT's winning arbitrage
            system since 2020 with no loss. Compound interest rates 10 times
            better than any bank in the world!
          </h6>
          <h6 className="text-xl text-white-100">Trusted by 50k+ users</h6>
          <div className="hero-content-input">
            <div className="hero-content-input-group flex flex-col">
              <input type="text"></input>
              <input type="text"></input>
              <input type="text"></input>
            </div>
            <div className="hero-content-input-group flex flex-col">
              <input type="text"></input>
              <input type="text"></input>
            </div>
            <label
              for="option1"
              className="flex items-center space-x-2 cursor-pointer"
            >
              <input
                type="radio"
                id="option1"
                name="options"
                className="form-radio text-blue-500"
              />
              <span className="text-gray-700">
                According to the last month data
              </span>
            </label>
            <button
              type="button"
              className="hero-content-btn flex items-center justify-center text-white-100"
            >
              Invest Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
